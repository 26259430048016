import { mapState, mapGetters, mapActions } from 'vuex'

// set your computed methods by mapping the state with data and setting our getters to call api;
export const utilityItemComputed = {
    ...mapState('utilityitem', {
        utilityitem:(state) => state.utility_items,
    }),
    ...mapGetters('utilityitem', ['getUtilityitem'])
}

// export property actions
export const utilityItemMethods = mapActions('utilityitem', ['allUtilityItem','createUtilityItem', 'updateUtilityItem', 'deleteUtilityItem',
])
